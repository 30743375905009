import React from 'react'

import {
  Button,
  EmptyState,
  List,
  ListItem,
  Paragraph,
  SearchIcon
} from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { Code } from '../../components/Code'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="EmptyState"
    components={[{ component: EmptyState }]}
    status={[{ type: 'accessible', version: '13.10.0' }]}
  >
    <Section>
      <List variant="unordered">
        <ListItem>
          Käytetään tyhjien tilojen näyttämiseen, esim. tyhjät listat,
          hakutulokset, yms.
        </ListItem>
        <ListItem>
          Komponentille voi määritellä ikonin, otsikon ja tekstin.
        </ListItem>
      </List>
    </Section>

    <Section title={'Pystymallinen asettelu'}>
      <List variant="unordered">
        <ListItem>Oletuksena käytetään pystymallista asettelua</ListItem>
        <ListItem>Tällöin EmptyState tasataan näkymän keskelle</ListItem>
      </List>
      <Playground>
        <EmptyState
          content="Muokkaa hakuehtoja ja tee uusi haku."
          icon={<SearchIcon />}
          title="Haku ei tuottanut tuloksia."
          variant={'vertical'}
        />
      </Playground>
    </Section>
    <Section title={'Vaakamallinen asettelu'}>
      <Paragraph>
        Vaakamallista asettelua voidaan käyttää, jos ladattava sisältö ja sen
        puuttumisesta ilmoittava EmptyState tasataan näkymässä vasemmalle.
      </Paragraph>
      <Playground>
        <EmptyState
          content="Muokkaa hakuehtoja ja tee uusi haku."
          icon={<SearchIcon />}
          title="Haku ei tuottanut tuloksia."
          variant={'horizontal'}
        />
      </Playground>
    </Section>
    <Section title="Call to action">
      <Paragraph>
        <Code>cta</Code>-propilla komponentin tekstin väri muuttuu tummemmaksi.
        Tätä kannattaa käyttää erityisesti mikäli halutaan korostaa
        toimintakehotusta.
      </Paragraph>
      <Playground>
        <EmptyState
          content={
            <>
              <Paragraph>Muokkaa hakuehtoja ja tee uusi haku.</Paragraph>
              <Button color="primary">Tee uusi haku</Button>
            </>
          }
          cta
          icon={<SearchIcon />}
          title="Haku ei tuottanut tuloksia."
        />
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Komponentti tuottaa semanttisesti leipätekstiä ilman korostuksia. Ikoni
        on kuvituksellinen, eli ei vaadi tekstivastinetta. Varmista, että teksti
        auttaa käyttäjää määrittämään toimenpiteen, kuten haun uudelleen.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
